<template>
  <div>
    <GuradiansFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(guardiansList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, [
                'Name',
                'Phone Number',
                'Email',
                'ASSOCIATED_STUDENTS',
              ])
            "
            :key="head.id"
          >
            <span v-i18n="dashboard">{{ head.dataTableName }}</span>
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            Campus
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')">Actions</TH>
        </TRHead>
      </THead>

      <TBody>
        <TRBody v-for="(guardian, index) in guardiansList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <SingleUserDisplay :user="guardian" label="full_name" :image="true" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
            <DisplayPhoneNumber :phone="guardian.phone" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ guardian.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
            {{ guardian.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
            {{
              (guardian.profile && guardian.profile.date_of_birth) ||
              GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
            {{
              (guardian.profile && guardian.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
            {{
              (guardian.profile && guardian.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{
              (guardian.profile && guardian?.profile?.address) || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD
            v-show="
              showColumnInDataTable(filtersData, 'ASSOCIATED_STUDENTS', ['ASSOCIATED_STUDENTS'])
            "
          >
            <MultiUserDisplay
              :options="guardian.students"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, guardiansList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, guardiansList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="
                guardian.login_activated_at
                  ? GENERAL_CONSTANTS.LOGIN_ENABLED
                  : GENERAL_CONSTANTS.LOGIN_DISABLED
              "
              :success="!!guardian.login_activated_at"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            <BadgeDisplay
              :options="guardian.users_roles_entities"
              :nested-label="['campus', 'title']"
              :not-show-initials="true"
              :is-object-concated="false"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="guardian"
              :idx="index"
              :action-list="guardian.actionsList"
              @action="guardianActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(guardiansCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />

    <!-- Add & Edit Guardian Modal -->
    <AddGuardianModal
      v-if="showGuardianModal"
      :modal="showGuardianModal"
      :edit="currentGuardian"
      @toggle="toggleGuardianModal"
    />
    <!-- Guardian Activation Modal -->
    <UserEnableModal @success="filterRecords" />
    <!-- Disable Modal -->
    <UserDisableModal @success="filterRecords" />
    <!-- Reset Password -->
    <ResetPasswordModal @success="filterRecords" />
    <RoleModal
      v-if="isRoleModal"
      :modal="isRoleModal"
      user-type="guardian"
      assigning-role="guardian"
      @toggle="toggleRoleModal"
    />
    <StudentAssociationModal
      v-if="associationModal"
      :modal="associationModal"
      :current-user="currentGuardian"
      user-type="guardian"
      @toggle="toggleAssociationModal"
    />
    <UserDeactivateModal @success="filterRecords" />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentGuardian?.full_name"
      remaining-message=""
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
  </div>
</template>

<script>
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import TableAction from '@/src/components/TableAction.vue'
import AddGuardianModal from '@views/admin/modals/AddGuardianModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import GuradiansFilter from '@router/views/guardians/GuardiansFilter.vue'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import { isEmpty } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import { fullName, getOptionsBasedOnUserStatus } from '@src/utils/settings/tenant-user.util.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'

import fileMixins from '@/src/mixins/file-mixins'
import { dropdownTopBottom } from '@src/utils/generalUtil.js'
import generalConstants from '@src/constants/general-constants.js'
import StudentAssociationModal from '@src/router/views/student/association/StudentGuadrianAssociationModal.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'

import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { guardiansGraphChart } from '@/src/router/views/guardians/guardians.util.js'
import guardiansData from '@/src/router/views/guardians/guardians.json'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  TableWrapper,
  THead,
  TRHead,
  TH,
  TBody,
  TRBody,
  TD,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    SingleUserDisplay,
    UserDisableModal,
    TableAction,
    AddGuardianModal,
    Loader,
    Pagination,
    ResetPasswordModal,
    GuradiansFilter,
    RoleModal,
    UserDeactivateModal,
    StudentAssociationModal,
    MultiUserDisplay,
    BadgeDisplay,
    DisplayPhoneNumber,
    UserEnableModal,
    NoRecordFound,
    UIConfirmationModal,
    UiStatus,
    TableWrapper,
    THead,
    TRHead,
    TH,
    TBody,
    TRBody,
    TD,
  },
  mixins: [generalUtil, fileMixins, scrollMixin],

  data() {
    return {
      forceRender: 0,
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      errorMsg: false,
      options: ['Select'],
      alwaysDisplayColumnsForCSV: {
        first_name: 'First Name',
        last_name: 'Last Name',
      },
      Guardain: null,
      tableHeaders: guardiansData.tableHeaders,
      defaultSelectedColumns: guardiansData.defaultSelectedColumns,
      guardiansList: null,
      currentGuardian: null,
      actionList: null,
      showGuardianModal: false,
      guardiansCounts: 0,
      showPagination: false,
      filteredRecordLimit: 10,
      filtersData: {},
      associationModal: false,
      isSetupPassword: false,
      isRoleModal: false,
    }
  },
  page: guardiansData.page,
  computed: {
    ...mapState({
      resetPassword: (state) => state.layout.resetPassword,
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showModal: (state) => state.layout.showModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      tabRightBar: (state) => state.layout.tabRightNav,
      showUiModal: (state) => state.layout.showUiModal,
    }),
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },
  watch: {
    enableLoginModal: {
      handler(val) {
        if (!val) this.currentGuardian = null
      },
    },
    showModal: {
      handler() {
        this.toggleGuardianModal()
      },
    },
    showDisableModal: {
      handler(val) {
        if (!val) this.currentGuardian = null
      },
    },
    resetPassword: {
      handler(val) {
        if (!val) this.currentGuardian = null
      },
    },
    currentCampusScope: {
      deep: true,
      handler() {
        this.filterRecords()
        this.forceRender++
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecords()
        this.forceRender++
      },
    },
    currentClassScope: {
      deep: true,
      handler() {
        this.filterRecords()
        this.forceRender++
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchGuardianStats()
      },
    },
    showUiModal: {
      handler(value) {
        if (value) this.toggleRoleModal()
      },
    },
  },
  mounted() {
    this.filterRecords()
    this.setRightBarData()
  },
  methods: {
    dropdownTopBottom,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
      this.forceRender++
    },
    fetchGuardianStats(payload = { $where: {} }) {
      this.getGuardianStats(payload).then((Guardain) => {
        this.setRightBarData(Guardain)
      })
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) {
        this.$store.dispatch('layout/setShowUiModal', false)
        this.currentGuardian = null
      }
      if (payload) {
        this.filterRecords()
      }
    },
    setRightBarData(Guardain) {
      let rightBarData = {
        header: {
          title: 'Guardians',
          buttons: !this.currentClassScope
            ? [
                {
                  title: 'RB_AG',
                  classes: ['primary-button-right'],
                  action: { name: 'layout/setShowModal' },
                },
                {
                  title: 'ADD_EXISTING_USER',
                  classes: ['primary-button-right'],
                  action: { name: 'layout/setShowUiModal' },
                },
              ]
            : [],
        },
        chartData: guardiansGraphChart(Guardain, this.currentCampusScope, this.currentSectionScope),
      }

      this.setRightbarContent(rightBarData)
    },
    /**
     * Perform action on guardians
     * @param {String} action - WHich action to perform
     * @param {Object} current - Current selected object
     */

    guardianActions(action, current) {
      this.currentGuardian = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentGuardian)
      switch (action) {
        case 'Edit':
          this.toggleGuardianModal()
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)

          break
        case 'A_STUDENT':
          this.toggleAssociationModal()
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentGuardian = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentGuardian?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    filterRecords(range) {
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getGuardiansWithFilter(payload)
        .then((res) => {
          this.guardiansList = res?.records
          this.guardiansList?.forEach((guardian) => {
            guardian.actionsList = this.tableActionList(guardian.login_activated_at)
            fullName(guardian)
            guardian.students.forEach((student) => {
              fullName(student)
            })
          })
          this.guardiansCounts = res?.meta?.total_records
          this.showPagination = res?.meta?.total_records > 10
          if (this.$route?.name === '/guardians') {
            this.fetchGuardianStats({ $where: this.filtersData?.$where })
          }
        })
        .finally(() => {
          this.noRecord = isEmpty(this.guardiansList)
          this.isLoading = false
          this.currentGuardian = null
          if (!range) this.forceRender++
        })
    },
    toggleGuardianModal(payload) {
      this.showGuardianModal = !this.showGuardianModal
      if (!this.showGuardianModal) this.currentGuardian = null
      if (payload) {
        this.filterRecords()
      }
    },
    tableActionList(userStatus) {
      this.actionList = [
        { name: 'Edit', basedOn: ['root-level', 'campus-level'] },
        { name: 'A_STUDENT' },
        { name: 'TV_DEACTIVATE_USER' },
      ]
      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      this.actionList = [...this.actionList, ...options]

      return this.actionList
    },
    toggleAssociationModal(payload) {
      this.associationModal = !this.associationModal
      if (payload) {
        this.filterRecords()
      }
      if (!this.associationModal) this.currentGuardian = null
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('guardian', ['getGuardianStats', 'getGuardiansWithFilter']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
