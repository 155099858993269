<template>
  <ValidationObserver @submit="submitForm">
    <UiModalContainer
      :modal-show="modal"
      :modal-width="50"
      footer-classes="flex justify-end gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit" v-i18n="dashboard">Edit Guardian</span>
        <span v-else v-i18n="dashboard">Add Guardian</span>
      </template>
      <template v-slot>
        <div class="checkInModal-body">
          <div
            v-if="currentStat < 1"
            class="mb-8 mx-2.5 flex justify-between flex-wrap flex-col lg:flex-row gap-2"
          >
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="flex items-center gap-2"
              :class="step.active ? 'active' : ''"
            >
              <div
                v-if="!step.markStatus"
                class="step-counter font-medium h-7 w-7 flex items-center justify-center text-menu border-menu border-2 rounded-full cursor-pointer"
              >
                <span>{{ index + 1 }}</span>
              </div>
              <span v-if="step.markStatus" class="iconBlock">
                <icon class="icon" icon="checkCircle" height="28" width="28" />
              </span>
              <span v-i18n="dashboard" class="step-info font-roboto font-medium text-menu text-sm">
                {{ step.info }}
              </span>
            </div>
          </div>
          <div v-if="currentStat === 1">
            <InputFieldWrapper>
              <UIInputBox
                v-model.trim="guardian.guardian_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                class="flex-1"
                label="First Name"
                placeholder="Enter First Name"
                rules="required|alpha_spaces"
              />
              <UIInputBox
                v-model.trim="guardian.guardian_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                class="flex-1"
                placeholder="Enter Last Name"
                rules="required|alpha_spaces"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                v-model="guardian.guardian_info.gender"
                title="Gender"
                class="flex-1"
                :options="genderArray"
              />
              <UIInputBox
                id="img-btn"
                ref="imgUpload"
                v-model="guardianImage"
                title="Photo"
                placeholder="Photo Upload"
                type="file"
                class="flex-1"
                :preview-image="previewImage"
                :rules="imageRules"
                @preview="preview"
                @clearValue="clearValue"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UIInputBox
                v-model="guardian.guardian_info.email"
                type="email"
                name="Email"
                class="flex-1"
                title="Email"
                label="Email"
                placeholder="Email"
                :rules="isEmailRequired ? 'required|email' : 'email'"
              />
              <PhoneNumber
                v-model="guardianPhoneNumber"
                title="Phone Number"
                class="flex-1"
                :rules="isPhoneNumberRequired ? 'required|validNumber' : 'validNumber'"
                @update="updateGuardianNumber"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDatePicker
                v-model="guardian.profile_info.date_of_birth"
                title="DOB"
                class="flex-1"
              />
              <UIInputBox
                v-model.trim="guardian.profile_info.religion"
                type="text"
                name="Religion"
                class="flex-1"
                title="Religion"
                label="Religion"
                placeholder="Religion"
                rules="alpha_spaces"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                v-model="guardian.profile_info.blood_group"
                title="Blood Group"
                class="flex-1"
                :options="bloodGroupArray"
              />
              <UIInputBox
                v-model="guardian.profile_info.address"
                type="text"
                name="Address"
                title="Address"
                class="flex-1"
                label="Address"
                placeholder="Address"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="flex-col-reverse">
              <UiSingleSelect
                v-model="guardian.identity_info.type"
                title="Identity Type"
                :options="identityType"
                label="title"
                reduce="value"
                class="flex-1"
                @change="setIdentityInfo()"
              />
              <UiSingleSelect
                v-if="!edit && !campusId"
                v-model="guardian.guardian_info.campus_id"
                title="CAMPUSES"
                :search-through-api="true"
                label="title"
                :options="campuses"
                class="flex-1"
                reduce="id"
                rules="required"
                @search="getCampus"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <div v-if="guardian.identity_info.type">
                <UIInputBox
                  v-model="guardian.identity_info.value"
                  type="text"
                  name="Identity Number"
                  title="Identity Number"
                  label="Identity Number"
                  placeholder="Enter Identity No"
                  class="flex-1"
                  rules="required|numeric"
                />
                <UIInputBox
                  v-model="guardian.identity_info.country"
                  type="text"
                  name="Country"
                  title="Country"
                  label="Country"
                  placeholder="Enter Country"
                  class="flex-1"
                  rules="required|alpha"
                />
              </div>
            </InputFieldWrapper>
            <InputFieldWrapper class="justify-end">
              <ToggleButton
                v-if="!edit"
                v-model="isGuardianActivated"
                heading="Enable Login"
                class="w-full sm:w-[48%]"
                :is-status-hide="true"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-if="currentStat < 3" v-slot:footer>
        <div v-if="currentStat !== 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else>
              {{ primaryButtonText }}
            </div>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UIInputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import icon from '@components/icons/icon.vue'
import { setObjectToEmptyFromNull, removeNestedObjectEmptyKeys } from '@src/utils/generalUtil.js'
import { transformGuardianData } from '@/src/utils/settings/tenant-user.util'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UIInputBox,
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    Loader,
    PhoneNumber,
    UiDatePicker,
    icon,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      currentStat: 1,
      steps: [
        { stat: 1, info: 'Personal Info', active: true, markStatus: false },
        // { stat: 2, info: 'Profile Info', active: false, markStatus: false },
      ],
      identityType: [
        { title: this.$t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
        { title: this.$t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
        { title: this.$t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
      ],
      guardian: {
        guardian_info: {
          first_name: '',
          last_name: '',
          email: '',
          gender: '',
          user_type: 'guardian',
          profession: '',
          phone: '',
          address: '',
          campus_id: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          height: '',
          weight: '',
          image: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
      },
      genderArray: ['male', 'female'],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      previewImage: null,
      guardianImage: null,
      guardianPhoneNumber: '',
      isGuardianActivated: true,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state?.layout?.isLoading || false,
      campusId: (state) => state?.layout?.currentCampusScope?.id || undefined,
      campuses: (state) => state?.layout?.campuses,
      currentSectionScope: (state) => state?.layout?.currentSectionScope?.id || undefined,
    }),
    primaryButtonText() {
      return this.edit ? 'Update' : 'Save'
    },
    isPhoneNumberRequired() {
      return this.isGuardianActivated && !this.guardian.guardian_info.email
    },
    isEmailRequired() {
      return this.isGuardianActivated && !this.guardianPhoneNumber
    },
    imageRules() {
      return RULE_CONSTANTS.SIZE
    },
  },
  created() {
    if (this.edit) this.setGuardianData()
  },
  methods: {
    setIdentityInfo() {
      this.guardian.identity_info.value = ''
      this.guardian.identity_info.country = ''
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.previewImage = null
          break
      }
    },
    /**
     * Set previous step inactive and mark its status true
     * Set next step active and mark its status false
     */
    nextStep() {
      if (this.currentStat < this.steps.length) {
        this.steps[this.currentStat - 1].active = false
        this.steps[this.currentStat - 1].markStatus = true
        this.currentStat += 1
        this.steps[this.currentStat - 1].markStatus = false
        this.steps[this.currentStat - 1].active = true
      }
    },
    /**
     * Go back to previous step
     * Mark all forward step inactive and mark their status false
     */

    prevStep() {
      if (this.currentStat > 1) {
        this.currentStat -= 1
      }
      if (this.currentStat > 0) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
        }
        this.steps[this.currentStat].active = false
        this.steps[this.currentStat].markStatus = false
        this.steps[this.currentStat - 1].markStatus = false
      }
    },
    /**
     * Submit form with guardian data
     * If edit existing guardian call editExistingguardian
     * If add new guardian call addNewguardian function
     * @param {Boolean} invalid - form data validation
     */
    submitForm() {
      if (this.currentStat === 1) {
        this.addNewGuardian()
      } else this.nextStep()
    },
    /**
     * Add new guardian
     * Append guardian data and its profile picture in form data
     * Send post request with form data
     */
    addNewGuardian() {
      this.$store.commit('layout/IS_LOADING', true)
      if (this.isGuardianActivated)
        this.guardian.guardian_info.login_activated_at = currentUTCDateTimeWithZone()
      removeNestedObjectEmptyKeys(this.guardian)
      const data = new FormData()
      if (this.isGuardianActivated) data.append('login_activated_at', currentUTCDateTimeWithZone())

      data.append('first_name', this.guardian?.guardian_info?.first_name || '')
      data.append('last_name', this.guardian?.guardian_info?.last_name || '')
      data.append('gender', this.guardian?.guardian_info?.gender || '')
      data.append('email', this.guardian?.guardian_info?.email || '')
      data.append('phone', this.guardian?.guardian_info?.phone || '')
      data.append('campus_id', this.guardian?.guardian_info?.campus_id || '')
      data.append('blood_group', this.guardian?.profile_info?.blood_group || '')
      data.append('date_of_birth', this.guardian?.profile_info?.date_of_birth || '')
      data.append('religion', this.guardian?.profile_info?.religion || '')
      data.append('type', this.guardian?.identity_info?.type || '')
      data.append('value', this.guardian?.identity_info?.value || '')
      data.append('country', this.guardian?.identity_info?.country || '')
      data.append('address', this.guardian?.profile_info?.address || '')
      if (this.guardianImage) data.append('profile_image', this.guardianImage)

      if (this.edit) {
        const payload = {
          guardian: data,
          id: this.edit?.id,
        }
        this.editGuardian(payload)
          .then(() => {
            this.$emit('toggle', true)
          })
          .finally(() => {
            this.$store.commit('layout/IS_LOADING', false)
          })
      } else {
        this.createGuardian(data)
          .then(() => {
            this.$emit('toggle', true)
          })
          .finally(() => {
            this.$store.commit('layout/IS_LOADING', false)
          })
      }
    },
    /**
     * Set selecte image to preview
     * @param {Object} image- selected image
     */
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.guardianImage = image
    },

    /**
     * Mobile phone handle
     */
    updateGuardianNumber(payload) {
      this.guardian.guardian_info.phone = payload.formattedNumber
    },

    setGuardianData() {
      this.guardian = transformGuardianData(this.edit)
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.guardianPhoneNumber = this.edit?.phone
      this.isGuardianActivated = !!this.edit?.login_activated_at
    },
    ...mapActions('guardian', ['createGuardian', 'editGuardian']),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>
