import GUARDIANS_DATA from '@/src/router/views/guardians/guardians.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const guardiansGraphChart = (guardians, campus, classId) => {
  let BAR_GRAPH_VALUE = [
    guardians?.total_guardian || GENERAL_CONSTANTS.EMPTY_RECORD,
    guardians?.profiles_completion_count || GENERAL_CONSTANTS.EMPTY_RECORD,
    guardians?.linked_guardians_count || GENERAL_CONSTANTS.EMPTY_RECORD,
    guardians?.not_linked_guardians_count || GENERAL_CONSTANTS.EMPTY_RECORD,
  ]
  let PIE_GRAPH_VALUE = [
    guardians?.login_disabled_count || GENERAL_CONSTANTS.EMPTY_RECORD,
    guardians?.login_enabled_count || GENERAL_CONSTANTS.EMPTY_RECORD,
  ]
  let BAR_LABELS_STRING =
    campus && !classId
      ? 'Campus Guardians'
      : classId && campus
      ? 'Section Guardians'
      : 'Institute Guardians'

  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      GUARDIANS_DATA.BAR_LABELS,
      BAR_GRAPH_VALUE,
      GUARDIANS_DATA.BAR_TITLE,
      BAR_LABELS_STRING,
    ),
    GRAPH_CONSTANTS.PIE_CHART(
      GUARDIANS_DATA.PIE_LABELS,
      PIE_GRAPH_VALUE,
      GUARDIANS_DATA.PIE_BACKGROUND_COLOR,
      GUARDIANS_DATA.PIE_TITLE,
    ),
  ]
  return chartData
}
