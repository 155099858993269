<template>
  <TitleWrapper
    :filter-option="true"
    title="Guardians"
    tooltip-title="GRDS"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @clear="clearFilters"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.first_name"
          :class="$style.widthItems"
          class="flex-1"
          title="First Name"
          type="text"
          placeholder="SEARCH_BY_FNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.FIRST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.last_name"
          :class="$style.widthItems"
          class="flex-1"
          title="Last Name"
          type="text"
          placeholder="SEARCH_BY_LNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.LAST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.email"
          class="flex-1"
          title="Email"
          placeholder="Email"
          :filter="FILTERS_LIST.EMAIL"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.gender"
          title="Gender"
          :options="GENDERS"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.GENDER"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.phone"
          title="Phone Number"
          placeholder="Phone Number"
          class="flex-1"
          :filter="FILTERS_LIST.PHONE"
          @emitQuery="updateQuery"
        />
        <UiMultiSelect
          v-model.trim="filtersPayload.student_ids"
          title="ASSOCAITED_STUDENTS"
          :options="studentList"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          input-color="bg-white"
          filter-key="associated_student_id"
          :image="true"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.STUDENTS"
          @emitQuery="updateQuery"
          @search="getStudents"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_after"
          title="DOB_GT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_GREATER_THAN"
          @change="birthDateAfter"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_before"
          title="DOB_LT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_LESS_THAN"
          @change="birthDateBefore"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.login_enabled"
          title="STATUS"
          :options="LOGIN_ENABLED_STATUSES"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.LOGIN_ENABLED_STATUS"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.address"
          class="flex-1"
          title="Address"
          type="text"
          placeholder="Address"
          :filter="FILTERS_LIST.ADDRESS"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import {
  formatObjectKeyValues,
  setObjectValuesToEmpty,
  addItemInArray,
  removeEmptyKeysFromObject,
  objectDeepCopy,
  getCountFromObject,
} from '@utils/generalUtil'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { mapActions } from 'vuex'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { mapState } from 'vuex'
import { USER_CONSTANTS } from '@src/constants/general-constants'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiSingleSelect,
    TitleWrapper,
    UiInput,
    UiDatePicker,
    UiMultiSelect,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      $where: {},
      queries: {},
      FILTERS_LIST,
      filtersCount: 0,
      studentList: [],
      selectedStudents: [],
      forceRender: 0,
      appliedQueries: {},
      GENDERS: USER_CONSTANTS.GENDERS,
      LOGIN_ENABLED_STATUSES: USER_CONSTANTS.LOGIN_ENABLED_STATUSES,
      filtersPayload: {
        first_name: '',
        last_name: '',
        gender: '',
        student_ids: '',
        email: '',
        phone: '',
        username: '',
        date_of_birth_before: '',
        date_of_birth_after: '',
        address: '',
        login_enabled: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
        this.getStudents()
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getStudents()
      },
    },
  },
  created() {
    this.updateDisplayedColumnList()
  },
  mounted() {
    this.getStudents()
  },
  methods: {
    getFiltersCount() {
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    getStudents(query) {
      let alreadySelectedStudents = []
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = objectDeepCopy(res?.records)
        this.studentList?.forEach((record) => {
          record.associated_student_id = record.student_info?.id
        })
        if (this.filtersPayload && this.filtersPayload.student_ids.length) {
          this.filtersPayload.student_ids.forEach((item) => {
            if (
              !this.studentList?.some(
                (value) => value.associated_student_id === item?.associated_student_id,
              )
            ) {
              this.studentList.unshift(item)
            }
            alreadySelectedStudents.push(item)
          })
        }
        this.selectedStudents = alreadySelectedStudents
        this.forceRender++
      })
    },
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
